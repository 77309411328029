@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');

/* div{
  background: rgb(34,193,195);
  background: linear-gradient(135deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  font-family: "work sans";
} */

::-webkit-input-placeholder {
    text-align: center;
  }
  
  :-moz-placeholder {
    text-align: center;
  }
  
  input {
    text-align: center;
  }
/* .row input{
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
  transition: box-shadow 0.2s ease-in;
}

.row input:focus{
   box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
   outline: none;
}

.row input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}
.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder{
  opacity: 0;
}

.row label{
  align-self: start;
  color: rgba(187,187,187,0.9);
}

.row h2{
  color: white;
  background: rgb(34,193,195);
  background: linear-gradient(90deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}


.row h2:hover{
  opacity: 0.8;
} */

  
