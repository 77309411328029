table.table tr th, table.table tr td {
  border-color: black;
  padding: 12px 15px;
  vertical-align: middle;
  border-collapse: collapse;
  border-bottom:2px solid black;

}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  
}

td, th, tr {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  border-bottom: 2pt solid black;
}

tr:nth-child(even) {
  background-color: #dddddd;
  border-bottom: 2pt solid black;
}
h4{
overflow:hidden;
white-space:nowrap;
text-overflow:ellipsis;
}