
  
  @keyframes flame-animation {
    0%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0) scale(0.75) rotate(0) scale(1);
    }
    25% {
      opacity: 0.35;
      transform: translate3d(0, -10%, 0) scale(1) rotate(-3deg) scale(1.05);
    }
    50% {
      opacity: 0.35;
      transform: translate3d(0, -4%, 0) scale(1) rotate(3deg) scale(1.1);
    }
    75% {
      opacity: 0.35;
      transform: translate3d(0, -20%, 0) scale(1) rotate(-3deg) scale(1.05);
    }
    99% {
      opacity: 0;
      transform: translate3d(0, -50%, 0) scale(0.8) rotate(0) scale(1);
    }
  }
  
  .fire {
    display: inline-block;
  }
  
  .flame {
    position: absolute;
    transform-origin: 70% 70%;
    z-index: 2;
    display: inline-block;
    top: calc(var(--flame-size) * -1px);
  }
  
  .flame.animate {
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-name: flame-animation;
    opacity: 0;
    z-index: 1;
    transform: translate3d(0, 15px, 0) scale(0.75) rotate(0);
    z-index: 1;
    animation-timing-function: ease-in;
  }
  
  .flame.animate:nth-child(2) {
    animation-delay: 0.5s;
  }
  .flame.animate:nth-child(3) {
    animation-delay: 1s;
  }
  
  /* below are optional styles */
  
 
  

  